import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Button, Card, CardContent, CircularProgress, Container, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../Styles/play.css';
import { ToastContainer, toast } from 'react-toastify';
import 'katex/dist/katex.min.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllChapterDetails, getAllCourseDetails, getAllSubjectDetails, addAssessmentImport, liveLectureReports, } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function StudentLectureReport() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = React.useState();
  const headers = ['SrNO', 'studentName', 'mobileNumber', 'videoWatchRatio'];
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [dataStudent, setDataStudent] = useState([]);
  const [questionTableData, setQuestionTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [lectureReports, setLectureReports] = useState([]);
  const [search, setSearch] = useState('')

  const [quizId, setQuizId] = useState('');
  const [addAssessData, setAddAssessData] = useState([]);

  console.log('check quizId', quizId);
  const [selectTags, setSelectTags] = useState('');
  const fileInputRef = useRef(null);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const onSelectCourse = (event) => {
    setSelectedCourse(event?.target?.value);
  };

  const onSelectsubject = (event) => {
    setSelectedSubject(event?.target?.value);
  };

  const onSelectChapter = (event) => {
    setSelectedChapter(event?.target?.value);
  };

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();

    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);
      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const fetchChapterData = async (courseId, subjectId) => {
    try {
      const chapterDetails = await getAllChapterDetails(courseId, subjectId);
      const chapterCode = chapterDetails?.data?.map((data) => ({
        chapterName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setChapter(chapterCode);
    } catch (error) {
      console.error('Error fetching chapter details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse && selectedSubject) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      if (selectedCourseObject && selectedSubjectObject) {
        fetchChapterData(selectedCourseObject.courseId, selectedSubjectObject.levelId);
      }
    }
  }, [selectedCourse, selectedSubject, Courses]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFormSubmit = () => {
    const file = uploadedFile;
    if (selectedCourse && selectedSubject && selectedChapter) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

      if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId;
        const chapterId = selectedChapterObject.levelId;

        setIsLoading(true);
        // Assuming you have a function like addQuizImport that accepts additional parameters
        addAssessmentImport(courseId, subjectId, chapterId, file)
          .then((response) => {
            if (response.data && response.data.success) {
              setAddAssessData(response.data);
              toast.success('File uploaded successfully!');
            } else {
              console.error('Unexpected response structure:', response.data);
            }

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error('AddCourse error:', error);
          });
      }
    }
  };
  console.log('addAssessData', addAssessData);


  const handlesubmit = () => {
    if (selectedCourse && selectedSubject && selectedChapter) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      const selectedSubjectObject = subject.find((subject) => subject.subjectName === selectedSubject);
      const selectedChapterObject = chapter.find((chapter) => chapter.chapterName === selectedChapter);

      if (selectedCourseObject && selectedSubjectObject && selectedChapterObject) {
        const courseId = selectedCourseObject.courseId;
        const subjectId = selectedSubjectObject.levelId;
        const chapterId = selectedChapterObject.levelId;

        console.log('Selected Course:', selectedCourse);
        console.log('Selected Subject:', selectedSubject);
        console.log('Selected Chapter:', selectedChapter);

        setIsLoading(true);
        setIsLoading(true);
        liveLectureReports(courseId, subjectId, chapterId)
          .then((response) => {
            if (response.data !== null) {
              // setQuestionBank(response.data);
              setTableData(response.data.data);
              setLectureReports(response.data.data);
              console.log('check content', response.data.data);
              setIsCardVisible(true);
              setQuizId(response?.data?.quizId);
              setIsLoading(false);
            } else {
              setIsCardVisible(false);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error('Error:', error);
          });
      }
    }
  };
  console.log("lectureReports", lectureReports)

  const handleSearch = (event) => {
    event.preventDefault();

    const searchData = lectureReports.filter((report) => {
      return report.studentName.includes(search) ||
        report.mobileNumber.toLowerCase().includes(search.toLowerCase())
    });

    setTableData(searchData);
  };
  const specificColumns = Array.isArray(tableData)
    ? tableData.map((item, serialNumber) => {
      console.log('div', item, item?.grade);
      return {
        SrNO: serialNumber + 1,

        studentName: item.studentName,
        mobileNumber: item?.mobileNumber,
        videoWatchRatio: item.videoWatchRatio,
        'Book Registered': item.bookRegistered,
        'Book Name': item.bookName,

        LastActive: item.lastActive,
      };
    })
    : [];

  const downloadLectureReport = () => {
    try {
      const filteredData = specificColumns.map((item, index) => {
        return {
          "Sr No": index + 1,
          "Student Name": item.studentName,
          "Mobile Number": item?.mobileNumber,
          "Video Watch Ratio": item.videoWatchRatio,
        };
      });
      const fileName = `StudentLectureReport.xlsx`;
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(filteredData), 'Data');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
      const blob = new Blob([s2ab(excelBuffer)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.log("Error downloading report:", error)
      toast.error("Error while download report")
    }
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i += 1) {
      view[i] = s.charCodeAt(i) % 256;
    }
    return buf;
  }


  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Student Report </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Student Lecture Report
          </Typography>
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >

            {lectureReports.length > 0 && <Stack> <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField name="search" label="Search"
                  variant="outlined" style={{ width: 320 }}
                  onChange={(e) => { setSearch(e.target.value) }}
                />
                <Button type="submit" variant="contained" style={{ height: '40px' }}>
                  Search
                </Button>
              </Stack>
            </form>
              <Button type="submit" variant="contained" onClick={downloadLectureReport} style={{ height: '40px', width: '150px' }}>
                Download
              </Button>
            </Stack>}
            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              {uploadedFile ? (
                <>
                  <Typography variant="body1" style={{ marginLeft: '10px' }}>
                    {uploadedFile.name}
                  </Typography>
                  <IconButton color="secondary" onClick={() => setUploadedFile(null)}>
                    <FaTrashAlt />
                  </IconButton>
                  <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                    Upload
                  </Button>
                </>
              ) : (
                <>
                  <label htmlFor="containedfile">
                    <Button variant="contained" color="primary" component="span" id="contained-button-file">
                      Import
                    </Button>
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx,.xls,.xlsx"
                      style={{ display: 'none' }}
                      id="containedfile"
                      onChange={handleFileUpload}
                      ref={fileInputRef}
                    />
                  </label>
                </>
              )}
            </div> */}
          </CardContent>
        </Stack>

        <form>
          <div className="main" style={{ display: 'flex' }}>
            <div className="col-md-3">
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Courses</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedCourse}
                  onChange={(e) => onSelectCourse(e)}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {Courses.map((course) => (
                    <MenuItem key={course.courseId} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Subject</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedSubject}
                  onChange={onSelectsubject}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {subject.map((subject) => (
                    <MenuItem key={subject.levelId} value={subject.subjectName}>
                      {subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-3" style={{ marginLeft: '10px' }}>
              <FormControl sx={{ m: 1, width: 270 }} size={'small'}>
                <InputLabel id="demo-multiple-name-label">Chapater</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={selectedChapter}
                  onChange={onSelectChapter}
                  input={<OutlinedInput label="Vehicle Model" />}
                  startAdornment={
                    <InputAdornment position="start">
                      {isLoading === true ? <CircularProgress size={15} /> : ''}
                    </InputAdornment>
                  }
                  MenuProps={MenuProps}
                >
                  {chapter.map((chapter) => (
                    <MenuItem key={chapter.levelId} value={chapter.chapterName}>
                      {chapter.chapterName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handlesubmit}
              sx={{ width: '120px', height: '40px', marginTop: '10px', textAlign: 'center' }}
            >
              Search
            </Button>
          </div>
        </form>
        <br />
        {isCardVisible && (
          <Card>
            <div style={{ alignItems: 'center' }}>
              <TableData
                // data={sortedData.length === 0 ? questionBankData : sortedData}
                data={specificColumns}
                headers={headers}
                rowsPerPageOptions={[10, 20, tableData.length]}
              />
            </div>
          </Card>
        )}
      </Container>
    </>
  );
}
