import React, { useEffect,useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Button, Typography, Container, Card, Avatar, Box, Grid, Dialog, DialogTitle, Divider, DialogContent, DialogActions, TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { FaPlane, FaRoad } from 'react-icons/fa';
import { Item } from 'semantic-ui-react';
import { generateAwbShipment, generatePickup, getShipSeverviceAvailability } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';

export default function GenerateAwbShipment() {
    const allCourierType = [{ name: 'All', icon: '' }, { name: 'Surface', icon: <FaRoad /> }, { name: 'Air', icon: <FaPlane /> }]
    const [shipServices, setShipServices] = useState(null)
    const [filterData, setFilteredData] = useState(null)
    const [selectedService, setSelectedService] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [courierType, setCourierType] = useState('All')
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const { order } = location.state;
    const [shipment, setShipment] = useState(null)
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [pickupDate, setPickupDate] = useState(new Date().toISOString().split('T')[0])

    const checkServiceAvailability = async () => {
        setLoading(true);
        try {
            const data = {
                "sourcePincode": "421302",
                "destinationPincode": order.pinCode,
                "weight": "3.5",
                "cod": "1",
                "partnerId": 3
            }
            getShipSeverviceAvailability(data).then((res) => {
                if (res.data.status === "SUCCESS") {
                    setShipServices(res.data.response);
                    setFilteredData(res.data.response);
                    setLoading(false)
                } else {
                    toast.error("Error while getting Shipping services")
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log("Error occured::", error)
            setLoading(false)
        }
    }
    useEffect(() => {
        console.log("object")
        console.log(order)
        checkServiceAvailability();
    }, []);

    const setCourierPartnerType = (partner) => {
        setCourierType(partner)
        switch (partner) {
            case 'All':
                setFilteredData(shipServices)
                break;
            case 'Surface':
                setFilteredData(shipServices.filter((service) => {
                    return service.is_surface === true;
                }));
                break;
            case 'Air':
                setFilteredData(shipServices.filter((service) => {
                    return service.is_surface === false;
                }));
                break;
            default:
                setFilteredData(shipServices)
        }
    }

    const generateAWB = async () => {
        console.log(selectedService)
        const data = {
            "shipment_id": order.shipmentId,
            "courier_id": selectedService.courier_company_id.toString(),
            "partner_id": 3
        }
        console.log("generateAwbShipment:", data)
        generateAwbShipment({}).then((res) => {
            setPopUpOpen(true)
            if (res.status === 200) {
                console.log(res.data)
                
            }
        })
    }

    const schedulePickUp = async () => {
        const data = {
            "partner_id": 3,
            "shipment_id": [
                order.shipmentId
            ],
            "pickup_date": [
                pickupDate
            ]
        }
        console.log("generatePickup:", data)
        generatePickup({}).then((res) => {
            console.log(res.data)
        })
    }

  return (
    <div>
          {loading && <Loader />}
          <Helmet>
              <title> Generate Awb Shipment </title>
          </Helmet>
          <ToastContainer />
          <Container>
              <h3>Generate Awb Shipment</h3>
              <Card style={{ padding: '10px 15px', borderRadius: '5px', marginBottom: '5px' }}>
                  <Grid style={{ marginBottom: '5px' }}>
                      {allCourierType.map((type) => {
                          return <Button variant={courierType === type.name ? "contained" : "outlined"} onClick={() => { setCourierPartnerType(type.name) }} style={{ margin: '2px' }}>{type.name} &nbsp; {type.icon} </Button>
                      })}
                  </Grid>
                  {filterData && `${filterData.length} Couriers available`}
              </Card>
              <Card style={{ padding: '10px 5px', borderRadius: '5px', border: '1px solid black', boxShadow: 'none', backgroundColor: 'rgb(193 188 188 / 15%)' }}>
                  <Grid container>
                      <Grid item xs={2}>
                          <Item><h4>Courier Partner</h4></Item>
                      </Grid>
                      <Grid item xs={2} textAlign={'center'}>
                          <Item><h4>Delivery Date</h4></Item>
                      </Grid>
                      <Grid item xs={2} textAlign={'center'}>
                          <Item><h4>Delivery Days</h4></Item>
                      </Grid>
                      <Grid item xs={2} textAlign={'center'}>
                          <Item><h4>Service Rate</h4></Item>
                      </Grid>
                      <Grid item xs={2} textAlign={'center'}>
                          <Item><h4>RTO Charges</h4></Item>
                      </Grid>
                      <Grid item xs={2} textAlign={'center'}>
                          <Item><h4>Ratings</h4></Item>
                      </Grid>
                  </Grid>
              </Card>
              {filterData && filterData.map((service) => {
                  return <Card key={service.id} sx={{ boxShadow: selectedService?.id === service?.id ? 0 : 2 }} style={{
                      cursor: 'pointer', marginTop: '10px', borderRadius: '5px', padding: '20px 15px',
                      border: selectedService?.id === service?.id ? '2px solid #3f50b5' : '',
                      backgroundColor: selectedService?.id === service?.id ? '#e3f2fd' : '#fff',
                  }}
                      onClick={() => setSelectedService(service)}
                  >
                      <Grid container spacing={1}>
                          <Grid item xs={2}>
                              <Box display="flex" alignItems="center">
                                  <Avatar alt={service?.courier_name} src="..." style={{ marginRight: 8 }} />
                                  <Typography variant="body1">{service?.courier_name}</Typography>
                              </Box>
                          </Grid>
                          <Grid item xs={2} textAlign={'center'}>
                              <Item><Typography variant="body1">{service?.etd}</Typography></Item>
                          </Grid>
                          <Grid item xs={2} textAlign={'center'}>
                              <Item><Typography variant="body1">{service?.estimated_delivery_days}</Typography></Item>
                          </Grid>
                          <Grid item xs={2} textAlign={'center'}>
                              <Item><Typography variant="body1">{service?.rate}</Typography></Item>
                          </Grid>
                          <Grid item xs={2} textAlign={'center'}>
                              <Item><Typography variant="body1">{service?.rto_charges}</Typography></Item>
                          </Grid>
                          <Grid item xs={2} textAlign={'center'}>
                              <Item><Typography variant="body1">{service?.rating}</Typography></Item>
                          </Grid>
                      </Grid>
                  </Card>
              })}

              <Button style={{ boxShadow: 'none ',marginTop:'5px' }} variant="contained" color="primary" onClick={generateAWB}>Generate Awb Shipment Order</Button>

              <Dialog maxWidth={'md'} scroll={'body'} fullWidth open={popUpOpen} onClose={() => { setPopUpOpen(false) }}>
                  <DialogTitle>Schedule Your Pickup</DialogTitle>
                  <Divider />
                  <DialogContent style={{ justifyContent: 'center', alignContent: 'center' }}>
                      <p>Your package has been assigned to <strong>{selectedService?.courier_name}</strong> successfully. The AWB number is of the same is <strong>{order.awbNo}</strong>.</p>
                      {/* <p>
                          <strong>Pick Up Location</strong> <br />
                          {selectedLocation?.firstName}, {selectedLocation?.city}/{selectedLocation?.state} - {selectedLocation?.pincode}
                      </p> */}
                      <p>
                          <strong>Select Pickup Date</strong> <br />

                          <TextField id="pickup-date" style={{ marginTop: 15 }}
                              label="Pickup Date" type="date" value={pickupDate}
                              inputProps={{ min: new Date().toISOString().split('T')[0] }}
                              onChange={(e) => { console.log(e.target.value) }}
                              InputLabelProps={{ shrink: true, }}
                          />
                      </p>
                      <Button style={{ boxShadow: 'none ' }} variant="contained" color="primary" onClick={() => { schedulePickUp() }}>Schedule Pick Up</Button>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => { setPopUpOpen(false) }} color="primary">Close</Button>
                  </DialogActions>
              </Dialog>
          </Container>
    </div>
  )
}
