import { Helmet } from 'react-helmet-async';
import { useEffect, useRef, useState } from 'react';
import { Stack, Button, Container, TextField, FormControl, Select, InputLabel, MenuItem, Checkbox, IconButton, DialogTitle, DialogContent, DialogActions, Table, Dialog, TableContainer, TableBody, TableRow, TableCell, TableHead, Divider, Typography, Grid, Box } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { saveAs } from 'file-saver'
import { FaEye, FaTrashAlt, FaDownload, FaRoute, FaListUl } from 'react-icons/fa';
import { getInvoiceList, getInvoiceExcel, importAwbReport, getInvoiceDetails, getOrderTrackingByAwb, getOrderComments, addSubStatus, generateLabel } from '../Servies/AdmiServices';
import TableData from '../components/common/TableData';
import Loader from '../components/common/Loader';

export default function InvoiceList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const headers = ['Select', 'Id', 'Order Id', 'Shipment Id', 'Transaction Date', 'Customer Name', 'Product', 'Amount', 'Invoice No', 'Order Status', 'Action'];
  const [showTable, setShowTable] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPageSize, setTotalPageSize] = useState(1)
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState('All');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [addAwbData, setAddAwbData] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [trackModalOpen, setTrackModelOpen] = useState(false)
  const [trackData, setTrackData] = useState(null)
  const [sCommentOpen, setsCommentOpen] = useState(false)
  const [sCommentData, setsCommentData] = useState({ id: null, data: null });
  const [shipComment, setShipComment] = useState('')

  const handleChange = (event) => {
    setOrderStatus(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleNameChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      fetchInvoices();
    }
  };

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await getInvoiceList({
        page: pageNo - 1,
        length: pageSize,
        search,
        order: [[0, 'desc']],
        data: {
          orderStatus: orderStatus === 'All' ? '' : orderStatus,
          fromDate: startDate,
          toDate: endDate,
        },
      });
      setShowTable(response.data.data);
      setTotalRecords(response.data.recordsTotal);
      setTotalPageSize(response.data.recordsTotal);
    } catch (error) {
      toast.error('Failed to fetch invoice data');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = () => {
    setPageNo(1); // Reset to first page on new search
    fetchInvoices();
  };

  useEffect(() => {
    fetchInvoices();
  }, [pageNo, pageSize]);

  const handleCheckboxChange = (invoiceNumber) => {
    setSelectedInvoices((prevSelected) =>
      prevSelected.includes(invoiceNumber)
        ? prevSelected.filter((num) => num !== invoiceNumber)
        : [...prevSelected, invoiceNumber]
    );
  };


  const downloadInvoices = async (invoiceNumber) => {
    const invoicesToDownload = selectedInvoices.length > 0 ? selectedInvoices : [invoiceNumber];
    console.log(selectedInvoices)
    if (invoicesToDownload.length > 0) {
      try {
        const response = await getInvoiceExcel(invoicesToDownload);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // Generate current date string
        const currentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
        // Create the file name with the current date
        const fileName = `ShipRocket_${currentDate}.zip`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        console.error('Error downloading the file');
        toast.error('Error downloading the file');
      }
    } else {
      toast.warn('No invoices selected');
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFormSubmit = () => {
    const file = uploadedFile;
    setIsLoading(true);
    // Assuming you have a function like addQuizImport that accepts additional parameters
    importAwbReport(file)
      .then((response) => {
        if (response.data && response.data.success) {
          setAddAwbData(response.data);
          toast.success('File uploaded successfully!');
        } else {
          console.error('Unexpected response structure:', response.data);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('AddCourse error:', error);
      });
  };

  const viewInvoiceDetails = async (OrderId) => {
    setIsLoading(true)
    try {
      getInvoiceDetails(OrderId).then((res) => {
        console.log(res.data.status)
        if (res.data.status === "SUCCESS") {
          setInvoiceData(res.data.response)
          setInvoiceOpen(true)
          console.log(res.data)
          setIsLoading(false)
        } else {
          toast.error("Error Getting Invoice Details...")
          setIsLoading(false)
        }

      });
    } catch (error) {
      console.log("Error Occured:", error)
      setIsLoading(false)
    }
  }

  const viewInvoiceTrack = async (awbNumber, partnerId) => {
    setIsLoading(true)
    try {
      getOrderTrackingByAwb(awbNumber, partnerId).then((res) => {
        if (res.data.status === "SUCCESS") {
          console.log(res.data.response);
          setTrackData(res.data.response);
          setTrackModelOpen(true);
          setIsLoading(false)
        } else {
          toast.error("Error Getting Order Details...")
          setIsLoading(false)
        }
      })
    } catch (error) {
      console.log("Error occured::", error)
      setIsLoading(false)
    }

  }

  const viewShipComment = async (id) => {
    setIsLoading(true)
    try {
      getOrderComments(id).then((res) => {
        if (res.data.status === "SUCCESS") {
          const commentData = { id, data: res.data.response }
          setsCommentData(commentData);
          setsCommentOpen(true)
          setIsLoading(false)
        } else {
          // toast.error(res.data.message)
          const commentData = { id, data: null }
          setsCommentData(commentData);
          setsCommentOpen(true)
          setIsLoading(false)
        }
      })
    } catch (error) {
      console.log("Error occured::", error)
      setIsLoading(false)

    }
  }

  const addNewShipComment = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = { "subStatus": shipComment, "portalTransactionId": sCommentData.id }
      addSubStatus(data).then((res) => {
        if (res.data.status === "SUCCESS") {
          setsCommentOpen(false);
          toast.success("Comment added");
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error("Error while adding comment")
        }
      })
    } catch (error) {
      setIsLoading(false)
      console.log("Error Occured::", error)
    }
  }

  console.log('addAssessData', addAwbData);

  const labelGeneration = (shipmentId) => {
    const data = {
      "shipment_id": [`${shipmentId}`],
      "partner_id": 3
    }
    console.log("labelGeneration:", data)
    generateLabel({ "abc": "abc" }).then((res) => {
      console.log(res.data)
      downloadLabelPDF(res.data.label_url)
    })
  }

  const downloadLabelPDF = async (labelUrl) => {
    try {
      const response = await axios({
        url: labelUrl,
        method: 'GET',
        responseType: 'blob',
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      saveAs(pdfBlob, 'shipping-label.pdf');
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
  };

  const specificColumns = Array.isArray(showTable)
    ? showTable.map((item, index) => {
      return {
        Select: (
          <Checkbox
            id={`select-checkbox-${index}`}
            checked={selectedInvoices.includes(item.invoiceNumber)}
            onChange={() => handleCheckboxChange(item.invoiceNumber)}
          />
        ),
        Id: item.id,
        'Order Id': item.orderId,
        'Shipment Id': item.shipmentId !== "" ? item.shipmentId : "-",
        'Transaction Date': formatDate(item.transactionDate),
        'Customer Name': item.customerName,
        Product: item.productTitle,
        Amount: item.totalsalevalue,
        'Invoice No': item.invoiceNumber,
        'Order Status': item.finalStatus,
        Action: (
          <div style={{ display: 'flex' }}>
            <FaDownload style={{ cursor: 'pointer', marginRight: '10px' }} title='Download Invoice' onClick={() => { window.open(item.invoiceLink, '_blank') }} />
            <FaEye style={{ cursor: 'pointer', marginRight: '10px' }} title='View Invoice' onClick={() => { viewInvoiceDetails(item.orderId) }} />
            {item.awbNo && <FaRoute style={{ cursor: 'pointer', marginRight: '10px' }} title='Track Order' onClick={() => { viewInvoiceTrack(item.awbNo, 3) }} />}
            <FaListUl style={{ cursor: 'pointer', marginRight: '10px' }} title='Order Comments' onClick={() => { viewShipComment(item.id) }} />
          </div>
        ),
        // 'Dispatch Order': (
        //   <div>
        //     <Button variant="contained" color="primary" onClick={() => { navigate('/dashboard/CreateShipment', { state: { order: item } }) }}> Create Shipment </Button>
        //     <Button variant="contained" color="primary" onClick={() => { navigate('/dashboard/GenerateAWB', { state: { order: item } }) }}> Generate AWB Shipment </Button>
        //   </div>
        // ),
        // 'Generate Label': (
        //   <div>
        //     <Button variant="contained" color="primary" onClick={() => { labelGeneration(12333) }}>Generate</Button>
        //   </div>
        // )

      };
    })
    : [];

  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Client </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Invoice List - Shipping
          </Typography>
        </Stack>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <FormControl style={{ width: '200px' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Order Status</InputLabel>
            <Select labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={orderStatus} onChange={handleChange}
              autoWidth label="Order Status"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="InProcess">InProcess</MenuItem>
              <MenuItem value="Ready To Dispatch">Ready To Dispatch</MenuItem>
              <MenuItem value="Dispatched">Dispatched</MenuItem>
              <MenuItem value="AWB Received">AWB Received</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
              <MenuItem value="Order Cancelled">Order Cancelled</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
            </Select>
          </FormControl>
          <TextField id="start-date"
            label="Start Date" type="date" value={startDate}
            onChange={handleStartDateChange} InputLabelProps={{ shrink: true, }}
          />
          <TextField id="end-date" label="End Date" type="date"
            value={endDate} onChange={handleEndDateChange} InputLabelProps={{ shrink: true, }}
          />
          <Button variant="contained" color="primary" onClick={handleSearchClick}> Search </Button>
        </div>
        <br />
        <div style={{ display: 'flex', gap: '2rem' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {uploadedFile ? (
              <>
                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                  {uploadedFile.name}
                </Typography>
                <IconButton color="secondary" onClick={() => setUploadedFile(null)}>
                  <FaTrashAlt />
                </IconButton>
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                  Update AWB
                </Button>
              </>
            ) : (
              <>
                <label htmlFor="containedfile">
                  <Button variant="contained" color="primary" component="span" id="contained-button-file">
                    Import
                  </Button>
                  <input
                    type="file"
                    accept=".pdf,.doc,.docx,.xls,.xlsx"
                    style={{ display: 'none' }}
                    id="containedfile"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                  />
                </label>
              </>
            )}
          </div>
          <Button variant="contained" color="primary" onClick={downloadInvoices}>
            Submit Selected
          </Button>
          <TextField
            id="search-name"
            label="Search by Name"
            type="text"
            value={search}
            onChange={handleNameChange}
            onKeyDown={handleSearchKeyPress}
            style={{ marginLeft: '500px' }}
          />
          <FormControl style={{ width: '150px' }}>
            <InputLabel id="page-size-select-label">Page Size</InputLabel>
            <Select
              labelId="page-size-select-label"
              id="page-size-select"
              value={pageSize}
              onChange={(e) => setPageSize(e.target.value)}
              autoWidth
              label="Page Size"
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={totalPageSize}>All</MenuItem>
            </Select>
          </FormControl>
        </div>
        <br />
        <TableData
          data={specificColumns}
          headers={headers}
          rowsPerPageOptions={[5, 10, 20]}
          page={pageNo}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={(newPage) => setPageNo(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
        <br />
        <Dialog maxWidth={'md'} scroll={'body'} fullWidth open={invoiceOpen} onClose={() => { setInvoiceOpen(false) }}>
          <DialogTitle>Invoice Details</DialogTitle>
          <Divider />
          {invoiceData && <DialogContent>
            <Typography><strong> User Info: </strong></Typography>
            <p style={{ marginTop: 0 }}>Customer Name : {invoiceData?.customerName} <br />
              Customer Email : {invoiceData?.email} <br />
              Customer Mobile : {invoiceData?.contactNo} <br />
            </p>
            <Typography><strong> Delivery Address: </strong></Typography>
            <p style={{ marginTop: 0 }}>Address: {invoiceData?.address1}, {invoiceData?.address2}, {invoiceData?.address3} <br />
              City : {invoiceData?.city}, {invoiceData?.state} - {invoiceData?.pinCode} <br />
            </p>
            <Typography><strong> Product Details: </strong></Typography>
            <p style={{ marginTop: 0 }}>Product ID : {invoiceData?.productId} <br />
              Product Title : {invoiceData?.productTitle} <br />
              Quantity: {invoiceData?.quantity} <br />
              Total Value : {invoiceData?.totalsalevalue}
            </p>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="body1">Subject Name</Typography></TableCell>
                    <TableCell><Typography variant="body1">Product Id</Typography></TableCell>
                    <TableCell><Typography variant="body1">Product Name</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData?.productList.map((product) => {
                    return (
                      <TableRow key={`key${product.id}`}>
                        <TableCell><Typography variant="body1">{product.subjectName}</Typography></TableCell>
                        <TableCell><Typography variant="body1">{product.productId}</Typography></TableCell>
                        <TableCell><Typography variant="body1">{product.courseDisplayName}</Typography></TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>}
          <DialogActions>
            <Button onClick={() => { setInvoiceOpen(false) }} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth={'md'} scroll={'body'} fullWidth open={trackModalOpen} onClose={() => { setTrackModelOpen(false) }}>
          <DialogTitle>Order Details</DialogTitle>
          <Divider />
          {trackData && <DialogContent>
            <Typography><strong> Shipment Info: </strong></Typography>
            <p style={{ marginTop: 0 }}>
              Courier Name : {trackData?.shipment_track[0]?.courier_name} <br />
              Current Status : {trackData?.shipment_track[0]?.current_status} <br />
              Consignee Name : {trackData?.shipment_track[0]?.consignee_name} <br />
              Picked From : {trackData?.shipment_track[0]?.origin}  Delivering To {trackData?.shipment_track[0]?.destination}<br />
              Pick-up Date : {trackData?.shipment_track[0]?.pickup_date} <br />
              Est. Delivery Date : {trackData?.shipment_track[0]?.edd} <br />
            </p>
            <p><a href={trackData?.track_url} target="_blank" rel="noopener noreferrer">Click Here To Track Order </a></p>
            <Divider />
            <div >
              <Timeline sx={{
                [`& .${timelineItemClasses.root}`]: {
                  flex: 0.2,
                  padding: 0,
                },
              }}>
                {trackData.shipment_track_activities && trackData.shipment_track_activities.sort((a, b) => new Date(b.date) - new Date(a.date)).map((activity) => {
                  return <TimelineItem>
                    <TimelineOppositeContent color="textSecondary">
                      {activity?.date}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <p>{activity.status} <br />
                        {activity.activity} <br />
                        {activity.location} <br />
                      </p>
                    </TimelineContent>
                  </TimelineItem>
                })}
              </Timeline>
            </div>
          </DialogContent>}
          <DialogActions>
            <Button onClick={() => { setTrackModelOpen(false) }} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog maxWidth={'md'} scroll={'body'} fullWidth open={sCommentOpen} onClose={() => { setsCommentOpen(false) }}>
          <DialogTitle>Ship Comment Details</DialogTitle>
          <Divider />
          <DialogContent style={{ justifyContent: 'center', alignContent: 'center' }}>
            <Box marginBottom={5}>
              <form onSubmit={addNewShipComment}>
                <TextField
                  name="comment"
                  label="Add Comment"
                  style={{ width: '260px', marginRight: '10px' }}
                  onChange={(e) => { setShipComment(e.target.value) }}
                />
                <button type="submit" style={{ width: '120px', height: '40px' }}>
                  Add Comment
                </button>
              </form>
            </Box>

            {sCommentData.data && <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="body1">Sub Status</Typography></TableCell>
                    <TableCell><Typography variant="body1">Commented On</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sCommentData.data.map((comment) => {
                    return (
                      <TableRow key={`key${comment.subStatusId}`}>
                        <TableCell><Typography variant="body1">{comment.subStatus}</Typography></TableCell>
                        <TableCell><Typography variant="body1">{new Date(comment.createdOn).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, })}</Typography></TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setsCommentOpen(false) }} color="primary">Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
