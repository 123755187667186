import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TextField, Switch, Stack, Typography, MenuItem, Select, InputLabel, } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { Assignbook, getAllCourseDetails, getAllSubjectDetails, getAssigncourses, deleteCourseUser, setScreenCast } from '../../../Servies/AdmiServices';
import Loader from '../../../components/common/Loader';
import { GENERAL_ERROR_MESSAGE, GENERAL_SUCCESS_MESSAGE } from '../../../utils/message';
// import TableData from 'src/components/common/TableData';

const AssignCoursePopup = ({ open, onClose, userId }) => {
  const headers = ['courseName', 'subjectName', 'isFree', 'startDate', 'endDate', 'uacCode', 'AllowCast', 'Action'];
  const [Courses, setCourese] = useState([]);
  const [subject, setSubject] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const initialNewCourseEntry = {
    courseName: '',
    subjectName: '',
    uacCode: '',
    startDate: '',
    endDate: '',
    isFree: false,
  };
  const [newCourseEntry, setNewCourseEntry] = useState([]);
  const fetchData = () => {
    if (open && userId) {
      getAssigncourses(userId)
        .then((res) => {
          if (res?.data?.SUCCESS === true) {
            setCourseData(res?.data?.Data);
            toast.success(GENERAL_SUCCESS_MESSAGE, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching course data:', error);
        });
    }
  };
  useEffect(() => {
    fetchData();
  }, [open, userId]);
  useEffect(() => {
    if (isLoading === true) {
      fetchData();
      setIsLoading(false);
    }
  }, [isLoading]);

  const handleChangeCourse = async () => {
    const courseDetails = await getAllCourseDetails();
    const courrseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));

    setCourese(courrseCode);
  };

  useEffect(() => {
    handleChangeCourse();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);

      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const handleAllowCast = (item) => {
    setIsLoading(true);
    setScreenCast(userId, item.courseId, item.subjectId).then((response) => {
      if (response.data?.status === "SUCCESS") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const updatedCourses = courseData.map((course) => {
          if (course.id === item.id) {
            return { ...course, castingEnabled: !course.castingEnabled };
          }
          return course;
        });
        setCourseData(updatedCourses);
        setIsLoading(false);
      } else {
        toast.error("Error while enabling casting...", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    })
  }

  const courses = courseData.map((item) => {
    return {
      courseName: item.courseName,
      subjectName: item.subjectName,
      uacCode: item.uacCode,
      startDate: item.startDate,
      endDate: item.endDate,
      isFree: <Switch checked={item.isFree === 'true'} color="primary" disabled />,
      AllowCast: <Switch checked={item.castingEnabled === true} onChange={() => { handleAllowCast(item) }} color="primary" disabled={item.castingEnabled === true} />,
      Action: <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDeleteCourse(item.id)} />,
    };
  });

  // Handle the delete action here, you can call an API or perform any other logic

  const handleDeleteCourse = (id) => {
    setIsLoading(true);

    deleteCourseUser(id).then((res) => {
      console.log(res?.data?.Data);
      if (res?.data.SUCCESS === true) {
        toast.success(res?.data?.Data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        fetchData();
      } else {
        setIsLoading(false);
        toast.error(GENERAL_ERROR_MESSAGE, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  // const handleAddRow = () => {
  //   setShowAddRow(true);
  //   setNewCourseEntry((prevEntries) => [...prevEntries, initialNewCourseEntry]);
  // };
  const handleAddRow = () => {
    setShowAddRow(true);
    setNewCourseEntry((prevEntries) => [
      ...prevEntries,
      {
        courseName: '', // Set default values here
        subjectName: '',
        uacCode: '',
        startDate: '', // Add default values for other fields if needed
        endDate: '',
        isFree: false,
      },
    ]);
  };

  // const handleCourseChange = (event, index) => {
  //   // const { value } = event.target;
  //   setSelectedCourse(event.target.value);
  //   setNewCourseEntry((prevEntries) => {
  //     const updatedEntries = [...prevEntries];
  //     updatedEntries[index] = {
  //       ...updatedEntries[index],
  //       courseName: selectedCourse,
  //     };
  //     return updatedEntries;
  //   });
  //   console.log(selectedCourse)
  // };
  // const handleSubjectChange = (event, index) => {
  //   const { value } = event.target;
  //   setSelectedSubjects(event.target.value);
  //   setNewCourseEntry((prevEntries) => {
  //     const updatedEntries = [...prevEntries];
  //     updatedEntries[index] = {
  //       ...updatedEntries[index],
  //       subjectName: selectedSubjects,
  //     };

  //     return updatedEntries;

  //   });
  //   console.log(selectedSubjects)
  // };

  const handleSaveAdd = () => {
    const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
    if (!selectedCourseObject) {
      console.error('Selected course not found');
      return;
    }

    // Find the selected subject object based on its name
    const selectedSubjectObject = subject.find((subjectItem) => subjectItem.subjectName === selectedSubjects);
    if (!selectedSubjectObject) {
      console.error('Selected subject not found');
      return;
    }
    const objectsToSave = newCourseEntry.map((entry) => ({
      courseId: selectedCourseObject.courseId,
      subjectId: selectedSubjectObject.levelId.toString(), // Convert subjectId to string
      userId,
      uaCode: entry.uacCode,
      latlong: '',
    }));

    setIsLoading(true);
    Assignbook(objectsToSave)
      .then((response) => {
        console.log(response);
        // if (response?.data.success === true) {
        setIsLoading(false);

        toast.success(response?.data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchData();
        // } else {
        //   setIsLoading(false);
        //   toast.error(GENERAL_ERROR_MESSAGE, {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching user data:', error);
      });
    setShowAddRow(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {isLoading && <Loader />}
      <ToastContainer />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
        <Typography variant="h4" gutterBottom>
          <DialogTitle>Course Mapping</DialogTitle>
        </Typography>
        <Button variant="contained" onClick={handleAddRow} style={{ marginRight: '10px' }}>
          Add Course
        </Button>
      </Stack>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {showAddRow &&
                newCourseEntry.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <InputLabel id={`course-label-${index}`}>Course</InputLabel>
                      <Select
                        variant="standard"
                        sx={{ m: 1, minWidth: 120 }}
                        value={newCourseEntry.courseName}
                        onChange={(event) => {
                          setSelectedCourse(event.target.value);

                          setNewCourseEntry((prevEntries) =>
                            prevEntries.map((item, idx) =>
                              idx === index ? { ...item, courseName: event.target.value } : item
                            )
                          );
                        }}
                      // onChange={(event) => handleCourseChange(event, index)}
                      >
                        {Courses.map((course) => (
                          <MenuItem key={course.courseId} value={course.courseName}>
                            {course.courseName}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>

                    <TableCell>
                      <InputLabel id={`subject-label-${index}`}>Subject</InputLabel>
                      {selectedCourse && (
                        <Select
                          variant="standard"
                          sx={{ m: 1, minWidth: 120 }}
                          value={newCourseEntry.subjectName}
                          onChange={(event) => {
                            setSelectedSubjects(event.target.value);
                            const selectedSubjectObject = subject.find(
                              (subjectItem) => subjectItem.subjectName === event.target.value
                            );
                            if (selectedSubjectObject) {
                              setNewCourseEntry((prevEntries) =>
                                prevEntries.map((item, idx) =>
                                  idx === index ? { ...item, subjectName: event.target.value } : item
                                )
                              );
                              console.log(newCourseEntry);
                              // setNewCourseEntry({ ...newCourseEntry, subjectName: event.target.value })}
                            }
                          }}
                        // onChange={(event) => handleSubjectChange(event, index)}
                        // onChange={(event) => setNewCourseEntry({ ...newCourseEntry, subjectName: event.target.value })}
                        >
                          {subject.map((subjectItem) => (
                            <MenuItem key={subjectItem.levelId} value={subjectItem.subjectName}>
                              {subjectItem.subjectName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={newCourseEntry.isFree}
                        color="primary"
                        onChange={(event) => {
                          console.log('Switch toggled:', event.target.checked);
                          setNewCourseEntry({ ...newCourseEntry, isFree: event.target.checked });
                        }}
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        variant="standard"
                        label="uacCode"
                        sx={{ m: 1, minWidth: 120 }}
                        value={newCourseEntry.uacCode}
                        onChange={(event) =>
                          setNewCourseEntry((prevEntries) =>
                            prevEntries.map((item, idx) =>
                              idx === index ? { ...item, uacCode: event.target.value } : item
                            )
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}

              {courses.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>{row.courseName}</TableCell>
                  <TableCell>{row.subjectName}</TableCell>
                  <TableCell>{row.isFree}</TableCell>
                  <TableCell>{row.startDate}</TableCell>
                  <TableCell>{row.endDate}</TableCell>
                  <TableCell>{row.uacCode}</TableCell>
                  <TableCell>{row.AllowCast}</TableCell>
                  <TableCell>{row.Action}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose(); // Close the dialog
            setShowAddRow(false);
            setNewCourseEntry([]); // Reset newCourseEntry state to initial state
          }}
          color="primary"
        >
          Close
        </Button>
        {showAddRow && (
          <Button onClick={handleSaveAdd} color="primary">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AssignCoursePopup;
