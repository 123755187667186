import {
  ADD_USER,
  ASSIGN_BOOK,
  CREATE_UAC,
  GET_ALL_CHAPTERS,
  GET_ALL_COURSE,
  GET_ALL_SUBJECT,
  GET_ALL_UAC,
  GET_ASSIGN_COURSES,
  GET_REPORT,
  GET_USER,
  SEARCH_USER,
  UAC_DOWNLOAD,
  GET_COURSES,
  GET_ALL_BOARDS,
  EDIT_COURSE_DETAIL,
  NOTIFICATION_SEND,
  NOTIFICATION_GET,
  GET_PULISHER,
  ADD_COURSE,
  UPDATE_COURSE,
  ADD_SHEDULAR,
  UPDATE_SHEDULE,
  EDIT_NOTIFICATION,
  GET_ALL_SHEDULAR,
  GET_EXCEL,
  GET_TEACHER_EDGE_UAC,
  DELETE_ASSIGNED_COURSE,
  DELETE_USER,
  GET_ALLCLIENTS,
  CREATE_CLIENT,
  CREATE_TEACHERS_EDGE,
  ASSIGNE_ROLE,
  DELETE_SCHEDULE,
  GET_QUIZ_LIST,
  GET_QUIZ_TAG,
  CREATE_QUIZ,
  PUBLISH_QUIZ,
  UPDATE_JOIN_QUIZ_STATUS,
  INITIATE_QUIZ,
  START_QUIZ,
  QUIZ_COMPLETE,
  GET_QUIZ_TO_PLAY,
  CHANGE_NEXT_QUESTION,
  IMPORT_QUIZ,
  GET_LEADER_BOARD,
  GET_USER_REPORT,
  GET_QUIZ_HISTORY,
  GET_JOINED_USERS,
  GET_ATTEMPTED_ANSWER_DATA,
  IMPORT_ASSESSMENT,
  GET_STUDENT_REPORT_CARD,
  GET_ASSESMENT_BY_ID,
  GET_FINAL_REPORT_CARD,
  IMPORT_ASSIGNMENT,
  GET_ASSIGNMENT_REPORT,
  LIVE_LECTURE_REPORT,
  GET_INVOICE_LIST,
  SHIPROCKET_EXCEL,
  GENERATE_CONTENT_QR,
  IMPORT_AWB_SHIPROCKET,
  GET_QUIZ_SUMMARY_REPORT,
  ALL_TE_CLIENT,
  GET_UAC_CODE_CLIENT,
  CREATE_UAC_FOR_CLIENT,
  SCREEN_CAST,
  UPDATE_USER_PROFILE_NAME,
  GET_INVOICE_DETAILS,
  GET_ALL_PICKUP_LOCATIONS,
  SHIP_SERVICE_AVAILABILITY,
  SHIP_TRACK_BY_AWB,
  GET_SUB_STATUS_LIST,
  ADD_SUB_STATUS,
  CREATE_ORDER_BY_PARTNER,
  GENERATE_AWB_SHIPMENT,
  GENERATE_PICKUP,
  GENERATE_LABEL,
} from '../Api/URLS';
import api from '../Api/api';

const getReport = (mobileNumber, uacCode) => {
  return api
    .post(GET_REPORT, {
      mobileNumber,
      uacCode,
    })
    .then((response) => {
      return response;
    });
};

const getExcel = (mobileNumber, uacCode, isUserActivity, isUserDetails) => {
  return api
    .post(GET_EXCEL, {
      mobileNumber,
      uacCode,
      isUserActivity,
      isUserDetails,
    })
    .then((response) => {
      return response;
    });
};

const deleteCourseUser = (id) => {
  return api.get(`${DELETE_ASSIGNED_COURSE}?id=${id}`).then((response) => {
    return response;
  });
};

const getAllCourseDetails = () => {
  return api.get(GET_ALL_COURSE).then((response) => {
    return response;
  });
};

const getQuizTags = () => {
  return api.get(GET_QUIZ_TAG).then((response) => {
    return response;
  });
};

const getAllQuizList = () => {
  return api.get(GET_QUIZ_LIST).then((response) => {
    return response;
  });
};

const getpublisher = () => {
  return api.get(GET_PULISHER).then((response) => {
    return response;
  });
};
const getAllBoardDetails = () => {
  return api.get(GET_ALL_BOARDS).then((response) => {
    return response;
  });
};

const getAllSubjectDetails = (courseId) => {
  return api.get(`${GET_ALL_SUBJECT}?course_id=${courseId}`).then((response) => {
    return response;
  });
};
const deleteUsers = (userId) => {
  return api.get(`${DELETE_USER}?userId=${userId}`).then((response) => {
    return response;
  });
};

const downloadUac = (genId) => {
  return api.get(`${UAC_DOWNLOAD}?gen_id=${genId}`).then((response) => {
    return response;
  });
};
const getAllChapterDetails = (courseId, subjectId) => {
  return api.get(`${GET_ALL_CHAPTERS}?course_id=${courseId}&subject_id=${subjectId}`).then((response) => {
    return response;
  });
};

const getTeacherEdgeUac = (clientId) => {
  return api.get(`${GET_TEACHER_EDGE_UAC}?client_id=${clientId}`).then((response) => {
    return response;
  });
};
const AddCourse = (BoradId, coursename, coursedisplayname, publisherid, file) => {
  const formData = new FormData();
  formData.append('board_id', BoradId);
  formData.append('course_name', coursename);
  formData.append('course_display_name', coursedisplayname);
  formData.append('publisher_id', publisherid);
  formData.append('file', file);

  return api
    .post(ADD_COURSE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};
const updateCourse = (courseId, file) => {
  const formData = new FormData();
  formData.append('course_id', courseId);
  formData.append('file', file);

  return api
    .post(UPDATE_COURSE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};

const importAssignment = (scheduleId, file) => {
  const formData = new FormData();
  formData.append('scheduleId', scheduleId);
  formData.append('file', file);

  return api
    .post(IMPORT_ASSIGNMENT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};

const getAllManageUAC = () => {
  return api.get(GET_ALL_UAC).then((response) => {
    return response;
  });
};

const getAllManageDemoUAC = (role) => {
  return api.get(GET_ALL_UAC, { role }).then((response) => {
    return response;
  });
};

const getAllCourses = () => {
  return api.get(GET_COURSES).then((response) => {
    return response;
  });
};

const CreatenewUac = (schoolName, uACCount, randomString) => {
  return api
    .post(CREATE_UAC, {
      schoolName,
      uACCount,
      randomString,
    })
    .then((response) => {
      return response;
    });
};

const SerachUser = (name, contactNumber) => {
  return api
    .post(SEARCH_USER, {
      name,
      contactNumber,
    })
    .then((response) => {
      return response;
    });
};

const getUser = (userId) => {
  return api
    .post(GET_USER, {
      userId,
    })
    .then((response) => {
      return response;
    });
};

const editCourseDetail = (courseId) => {
  return api.get(`${EDIT_COURSE_DETAIL}?courseId=${courseId}`).then((response) => {
    return response;
  });
};
const addUsers = (data) => {
  return api.post(ADD_USER, data).then((response) => {
    return response;
  });
};

const getAssignmentResult = (scheduleId) => {
  return api.post(GET_ASSIGNMENT_REPORT, { scheduleId }).then((response) => {
    return response;
  });
};

const getAssigncourses = (userId) => {
  return api
    .post(GET_ASSIGN_COURSES, {
      userId,
    })
    .then((response) => {
      return response;
    });
};
const Assignbook = (payload) => {
  return api.post(ASSIGN_BOOK, payload).then((response) => {
    return response;
  });
};

const sendnotification = (payload) => {
  return api.post(NOTIFICATION_SEND, payload).then((response) => {
    return response;
  });
};

const getnotification = (payload) => {
  return api.post(NOTIFICATION_GET, payload).then((response) => {
    return response;
  });
};

const addShedule = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return api
    .post(ADD_SHEDULAR, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};
const getShedule = (pageNo, pageSize) => {
  return api
    .post(GET_ALL_SHEDULAR, {
      pageNo,
      pageSize,
    })
    .then((response) => {
      return response;
    });
};

const getClients = () => {
  return api.get(GET_ALLCLIENTS, {}).then((response) => {
    return response;
  });
};
const updateShedule = (payload) => {
  return api.post(UPDATE_SHEDULE, payload).then((response) => {
    return response;
  });
};

const editNotifcations = (id) => {
  return api.get(`${EDIT_NOTIFICATION}?id=${id}`).then((response) => {
    return response;
  });
};

const deleteSchedule = (id) => {
  return api.get(`${DELETE_SCHEDULE}?id=${id}`).then((response) => {
    return response;
  });
};

const createClient = (payload) => {
  return api.post(process.env.REACT_APP_API_URLS + CREATE_CLIENT, payload).then((response) => {
    return response;
  });
};

const createTeachersEdge = (payload) => {
  return api.post(CREATE_TEACHERS_EDGE, payload).then((response) => {
    return response;
  });
};

const assignRole = (phoneNumber, role) => {
  return api
    .post(ASSIGNE_ROLE, {
      phoneNumber,
      role,
    })
    .then((response) => {
      return response;
    });
};

const getQuestionBank = (data) => {
  return api.post(CREATE_QUIZ, data).then((response) => {
    return response;
  });
};
const updatejoinStatus = (quizId, quizStatus) => {
  return api.post(UPDATE_JOIN_QUIZ_STATUS, { quizId, quizStatus }).then((response) => {
    return response;
  });
};

const postQuiz = (quizId, quizQuestionTimeInSeconds, scheduleDateTime, quizName, streamingUrl, isQuizManual) => {
  return api
    .post(PUBLISH_QUIZ, { quizId, quizQuestionTimeInSeconds, scheduleDateTime, quizName, streamingUrl, isQuizManual })
    .then((response) => {
      return response;
    });
};

const initiateQuizStatus = (quizId, quizStatus) => {
  return api.post(INITIATE_QUIZ, { quizId, quizStatus }).then((response) => {
    return response;
  });
};

const startQuizStatus = (quizId, quizStatus, questionId, currentQuestion) => {
  return api.post(START_QUIZ, { quizId, quizStatus, questionId, currentQuestion }).then((response) => {
    return response;
  });
};

const quizStatusComplete = (quizId, quizStatus) => {
  return api.post(QUIZ_COMPLETE, { quizId, quizStatus }).then((response) => {
    return response;
  });
};

const getQuizsToPlay = (quizId) => {
  return api.get(`${GET_QUIZ_TO_PLAY}?quizId=${quizId}`).then((response) => {
    return response;
  });
};

const getLeaderBoardData = (quizId) => {
  return api.get(`${GET_LEADER_BOARD}?quizId=${quizId}`).then((response) => {
    return response;
  });
};

const getUserReports = (quizId) => {
  return api.get(`${GET_USER_REPORT}?quizId=${quizId}`).then((response) => {
    return response;
  });
};

const quizStatusNext = (quizId, questionId, quizStatus, currentQuestion) => {
  return api.post(CHANGE_NEXT_QUESTION, { quizId, questionId, quizStatus, currentQuestion }).then((response) => {
    return response;
  });
};

const addQuizImport = (courseId, subjectId, chapterId, tag, file) => {
  const formData = new FormData();
  formData.append('courseId', courseId);
  formData.append('subjectId', subjectId);
  formData.append('chapterId', chapterId);
  formData.append('tag', tag);
  formData.append('file', file);
  return api
    .post(IMPORT_QUIZ, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};

const addAssessmentImport = (courseId, subjectId, chapterId, file) => {
  const formData = new FormData();
  formData.append('courseId', courseId);
  formData.append('subjectId', subjectId);
  formData.append('chapterId', chapterId);
  formData.append('file', file);
  return api
    .post(IMPORT_ASSESSMENT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};

const getExpireQuiz = () => {
  return api.get(GET_QUIZ_HISTORY).then((response) => {
    return response;
  });
};

const getJoinedUser = (quizId) => {
  return api.get(`${GET_JOINED_USERS}?quizId=${quizId}`).then((response) => {
    return response;
  });
};

const getAttemptedUserList = (quizId, questionId) => {
  return api.post(GET_ATTEMPTED_ANSWER_DATA, { quizId, questionId }).then((response) => {
    return response;
  });
};

const getStudentReportCard = (data) => {
  console.log(data, 'check data');
  return api.post(GET_STUDENT_REPORT_CARD, data).then((response) => {
    return response;
  });
};

const getFinalReportCard = (data) => {
  console.log(data, 'check data');
  return api.post(GET_FINAL_REPORT_CARD, data).then((response) => {
    return response;
  });
};

const getQuestionById = (courseId, subjectId, chapterId) => {
  return api.post(GET_ASSESMENT_BY_ID, { courseId, subjectId, chapterId }).then((response) => {
    return response;
  });
};

const liveLectureReports = (courseId, subjectId, chapterId) => {
  return api.post(LIVE_LECTURE_REPORT, { courseId, subjectId, chapterId }).then((response) => {
    return response;
  });
};

const getInvoiceList = (requestData) => {
  return api.post(process.env.REACT_APP_API_URLS + GET_INVOICE_LIST, requestData).then((response) => {
    return response;
  });
};

const getInvoiceExcel = (invoiceNumbers) => {
  return fetch(`${process.env.REACT_APP_API_URLS}${SHIPROCKET_EXCEL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(invoiceNumbers),
  });
};

const generateContentQR = (courseId, subjectId) => {
  const token = localStorage.getItem('token');
  return fetch(`${process.env.REACT_APP_API_URL}${GENERATE_CONTENT_QR}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ courseId, subjectId }),
  });
};

const getQuizeSummaryReport = (userId) => {
  return api
    .get(`${GET_QUIZ_SUMMARY_REPORT}`, {
      headers: {
        'userId': `${userId}`
      },
    })
    .then((response) => {
      return response;
    });
};

const setScreenCast = (userId, courseId, subjectId) => {
  return api.post(SCREEN_CAST, { userId, courseId, subjectId }).then((response) => {
    return response;
  })
}

const updateUserProfileName = (userId, userProfileName) => {
  return api.post(UPDATE_USER_PROFILE_NAME, { userId, userProfileName }).then((response) => {
    return response;
  })
}

const importAwbReport = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return api
    .post(process.env.REACT_APP_API_URLS + IMPORT_AWB_SHIPROCKET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      return response;
    });
};

const getAllTEClient = () => {
  return api.get(process.env.REACT_APP_API_URLS + ALL_TE_CLIENT).then((response) => {
    return response;
  });
}

const getUacCodeForClient = (clientId) => {
  return api.get(process.env.REACT_APP_API_URLS + GET_UAC_CODE_CLIENT + clientId).then((response) => {
    return response;
  });
}

const createUacForClient = (data) => {
  return api.post(process.env.REACT_APP_API_URLS + CREATE_UAC_FOR_CLIENT, data).then((respsonse) => {
    return respsonse;
  })
}

const getInvoiceDetails = (OrderId) => {
  return api.get(process.env.REACT_APP_API_URLS + GET_INVOICE_DETAILS + OrderId).then((response) => {
    return response;
  });
};

const getAllPickupLocations = () => {
  return api.get(process.env.REACT_APP_API_URLS + GET_ALL_PICKUP_LOCATIONS).then((response) => {
    return response;
  })
}

const getShipSeverviceAvailability = (data) => {
  return api.post(process.env.REACT_APP_API_URLS + SHIP_SERVICE_AVAILABILITY, data).then((response) => {
    return response;
  })
}

const getOrderTrackingByAwb = (awbNumber, partnerId) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${SHIP_TRACK_BY_AWB}${awbNumber}&partnerId=${partnerId}`).then((response) => {
    return response;
  })
}

const getOrderComments = (Id) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${GET_SUB_STATUS_LIST}${Id}`).then((response) => {
    return response;
  });
}

const addSubStatus = (data) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${ADD_SUB_STATUS}`, data).then((response) => {
    return response;
  });
}

const createOrderByPartner = (data) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${CREATE_ORDER_BY_PARTNER}`, data).then((response) => {
    return response;
  })
}

const generateAwbShipment = (data) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${GENERATE_AWB_SHIPMENT}`, data).then((response) => {
    return response;
  })
}

const generatePickup = (data) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${GENERATE_PICKUP}`, data).then((response) => {
    return response;
  })
}

const generateLabel = (data) => {
  return api.post(`${process.env.REACT_APP_API_URLS}${GENERATE_LABEL}`, data).then((response) => {
    return response;
  })
}


export {
  getReport,
  getAllCourseDetails,
  getAllSubjectDetails,
  getAllChapterDetails,
  getAllManageUAC,
  CreatenewUac,
  SerachUser,
  getUser,
  addUsers,
  getAssigncourses,
  Assignbook,
  downloadUac,
  sendnotification,
  getAllBoardDetails,
  editCourseDetail,
  getAllCourses,
  getnotification,
  getpublisher,
  AddCourse,
  updateCourse,
  addShedule,
  getShedule,
  updateShedule,
  editNotifcations,
  getExcel,
  getTeacherEdgeUac,
  deleteCourseUser,
  deleteUsers,
  getClients,
  createClient,
  createTeachersEdge,
  assignRole,
  deleteSchedule,
  getAllQuizList,
  getQuizTags,
  getQuestionBank,
  postQuiz,
  updatejoinStatus,
  initiateQuizStatus,
  startQuizStatus,
  getQuizsToPlay,
  quizStatusComplete,
  quizStatusNext,
  addQuizImport,
  getLeaderBoardData,
  getUserReports,
  getExpireQuiz,
  getJoinedUser,
  getAttemptedUserList,
  getAllManageDemoUAC,
  addAssessmentImport,
  getStudentReportCard,
  getQuestionById,
  getFinalReportCard,
  importAssignment,
  getAssignmentResult,
  liveLectureReports,
  getInvoiceList,
  getInvoiceExcel,
  generateContentQR,
  getQuizeSummaryReport,
  importAwbReport,
  getAllTEClient,
  getUacCodeForClient,
  createUacForClient,
  setScreenCast,
  updateUserProfileName,
  getInvoiceDetails,
  getAllPickupLocations,
  getShipSeverviceAvailability,
  getOrderTrackingByAwb,
  getOrderComments,
  addSubStatus,
  createOrderByPartner,
  generateAwbShipment,
  generatePickup,
  generateLabel,
};
