import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Select,
    Button,
    MenuItem,
} from '@mui/material';

const NewTableData = ({ data, headers,currentPage,totalPages }) => {

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <TableRow key={index}>
                            {headers.map((header) => (
                                <TableCell key={header}>{item[header]}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0" }}>
        <div>
          {/* <Button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button> */}
          <span style={{ margin: "0 10px" }}>
            Page {currentPage} of {totalPages}
          </span>
          {/* <Button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button> */}
        </div>
        
      </div>

        </TableContainer>
    );
};

export default NewTableData;
