import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { FaEdit, FaTrash, FaTrashAlt } from 'react-icons/fa';
import { Container, Typography, Button, Card, CardContent, FormControl, Select, CircularProgress, MenuItem, InputAdornment, OutlinedInput, TextField, Stack, Tooltip, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { addShedule, getShedule, updateShedule, getAllCourseDetails, getAllSubjectDetails, deleteSchedule, importAssignment, getAssignmentResult, } from '../Servies/AdmiServices';
import 'react-toastify/dist/ReactToastify.css';
import NewTableData from '../components/common/NewTableData';
import Loader from '../components/common/Loader';

export default function Scheduler() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showTable, setShowTable] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  // const [pageSize, setPageSize] = useState(20);
  const [totalRecord,setTotalRecord]=useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const [editingRow, setEditingRow] = useState(null)
  const [editedData, setEditedData] = useState({});
  const [Courses, setCourse] = useState([]);
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [uploadedFileAs, setUploadedFileAs] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState('');
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const newPageSize = 10;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const onSelectCourse = (event) => {
    const selectedCourseObject = Courses.find((course) => course.courseName === event.target.value);
    setSelectedCourse(selectedCourseObject);
    setEditedData({ ...editedData, courseId: selectedCourseObject.courseId });

    if (selectedCourseObject) {
      fetchSubjectData(selectedCourseObject.courseId);
    }
  };

  const onSelectSubject = (event) => {
    const selectedSubjectObject = subject.find((subject) => subject.subjectName === event.target.value);
    setSelectedSubject(selectedSubjectObject);
    setEditedData({ ...editedData, subjectId: selectedSubjectObject.levelId });
  };
  console.log('check subject', selectedSubject);
  const handleChangeCourseList = async () => {
    const courseDetails = await getAllCourseDetails();
    const courseCode = courseDetails?.data?.map((data) => ({
      courseName: data?.courseDisplayName,
      courseId: data?.courseId,
    }));
    setCourse(courseCode);
  };
  useEffect(() => {
    handleChangeCourseList();
  }, []);

  const fetchSubjectData = async (courseId) => {
    try {
      const subjectDetails = await getAllSubjectDetails(courseId);

      const subjectCode = subjectDetails?.data?.map((data) => ({
        subjectName: data?.levelDisplayName,
        levelId: data?.levelId,
      }));

      setSubject(subjectCode);
    } catch (error) {
      console.error('Error fetching subject details:', error);
    }
  };

  useEffect(() => {
    if (selectedCourse) {
      const selectedCourseObject = Courses.find((course) => course.courseName === selectedCourse);
      if (selectedCourseObject) {
        fetchSubjectData(selectedCourseObject.courseId);
      }
    }
  }, [selectedCourse, Courses]);

  const handleReportClick = (id) => {
    navigate(`/dashboard/AssignmentResult?id=${id}`);
    console.log(id, 'check iddd');
  };

  const fetchData = () => {
    console.log("pageNo:: ", pageNo);
    getShedule(pageNo, 20)
      .then((response) => {
        const scheduleData = response.data.data;
        setTotalRecord(response.data.totalPage)
        setShowTable(scheduleData);
        setFilteredData(scheduleData)
        const ids = scheduleData.map((item) => item.id);
        console.log(ids);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
  useEffect(() => {
    fetchData();
    console.log("Init");
  }, []);

  const handleNextPage = () => {
    setPageNo(pageNo+1)
  };

  const handlePrevPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };
  
  useEffect(()=>{
    fetchData()
    console.log("Page");
  },[pageNo]);
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const handleFileUploadAs = (event, id) => {
    const file = event.target.files[0];
    setUploadedFileAs({ file, id });
  };

  const handleFormSubmit = () => {
    const file = uploadedFile;
    setIsLoading(true);
    addShedule(file)
      .then((res) => {
        if (res?.data.success === true) {
          setIsLoading(false);
          fetchData();
        } else {
          setIsLoading(false);
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('AddCourse error:', error);
      });
  };

  const handleAssignmentUpload = () => {
    const { file, id } = uploadedFileAs;
    setIsLoading(true);
    importAssignment(id, file)
      .then((res) => {
        if (res?.data.success === true) {
          setIsLoading(false);
          fetchData();
        } else {
          setIsLoading(false);
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('AddCourse error:', error);
      });
  };

  const handleEditClick = (rowData) => {
    setEditingRow(rowData);
    setEditedData({ ...rowData }); // Store the original data
  };
  const handleDeleteSchedule = (id) => {
    setIsLoading(true);

    deleteSchedule(id).then((res) => {
      console.log(res?.data?.data);
      if (res?.data.SUCCESS === true) {
        toast.success(res?.data?.Data, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        fetchData();
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleSaveClick = () => {
    console.log(editedData)
    updateShedule(editedData)
      .then((res) => {
        if (res?.status === 'FAILED') {
          setIsLoading(false);
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          setEditingRow(null);
          toast.success('Add schedule details successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchData();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Update Schedule error:', error);
      });
  };

  // useEffect(() => {
  //   if (isLoading === true) {
  //     fetchData();
  //     setIsLoading(false);
  //   }
  //   console.log("Loading");
  // }, [isLoading]);

  const headers = ['Course', 'Subject', 'ScheduleName', 'Chapter Name', 'Topics to be prepared',
    // 'ClassWork', // 'PostWork', 
    'TeacherName', 'Duration', 'StartTime', 'Link', 'Status', 'Action',];

  const specificColumns = Array.isArray(filteredData)
    ? filteredData.map((item) => {
      const isEditing = editingRow && editingRow.id === item.id;

      return {
        Course: isEditing ? (
          <Select type="text" sx={{ m: 1, width: 200 }} size={'small'}
            value={selectedCourse ? selectedCourse.courseName : '' || item.courseDisplayName}
            onChange={onSelectCourse}
            startAdornment={
              <InputAdornment position="start">
                {isLoading === true ? <CircularProgress size={15} /> : ''}
              </InputAdornment>
            }
            MenuProps={MenuProps}
          >
            {Courses.map((course) => (
              <MenuItem key={course.courseId} value={course.courseName}>
                {course.courseName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          item.courseDisplayName
        ),
        Subject: isEditing ? (
          <Select type="text" sx={{ m: 1, width: 200 }} size={'small'}
            value={(selectedSubject ? selectedSubject.subjectName : '') || item.subjectDisplayName}
            onChange={onSelectSubject}
            startAdornment={
              <InputAdornment position="start">
                {isLoading === true ? <CircularProgress size={15} /> : ''}
              </InputAdornment>
            }
            MenuProps={MenuProps}
          >
            {subject.map((subject) => (
              <MenuItem key={subject.levelId} value={subject.subjectName}>
                {subject.subjectName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          item.subjectDisplayName
        ),

        ScheduleName: isEditing ? (
          <TextField type="text" sx={{ m: 1, width: 200 }}
            value={editedData.scheduleName || ''}
            onChange={(e) => setEditedData({ ...editedData, scheduleName: e.target.value })}
          />
        ) : (
          item.scheduleName
        ),
        'Chapter Name': isEditing ? (
          <TextField type="text" sx={{ m: 1, width: 200 }}
            value={editedData.chapterName || ''}
            onChange={(e) => setEditedData({ ...editedData, chapterName: e.target.value })}
          />
        ) : (
          item.chapterName
        ),

        'Topics to be prepared': isEditing ? (
          <TextField type="text" sx={{ m: 1, width: 200 }}
            value={editedData.topicsToBePrepared || ''}
            onChange={(e) => setEditedData({ ...editedData, topicsToBePrepared: e.target.value })}
          />
        ) : (
          <Tooltip title={item.topicsToBePrepared}>
            <p style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', }}>{item.topicsToBePrepared}</p>
          </Tooltip>
        ),

        // ClassWork: isEditing ? (
        //   <TextField
        //     type="text"
        //     sx={{ m: 1, width: 200 }}
        //     value={editedData.classWork || ''}
        //     onChange={(e) => setEditedData({ ...editedData, ClassWork: e.target.value })}
        //   />
        // ) : (
        //   item.classWork
        // ),
        // PostWork: isEditing ? (
        //   <TextField
        //     type="text"
        //     sx={{ m: 1, width: 200 }}
        //     value={editedData.postWork || ''}
        //     onChange={(e) => setEditedData({ ...editedData, PostWork: e.target.value })}
        //   />
        // ) : (
        //   item.postWork
        // ),
        TeacherName: isEditing ? (
          <TextField
            type="text"
            sx={{ m: 1, width: 200 }}
            value={editedData.teacherName || ''}
            onChange={(e) => setEditedData({ ...editedData, teacherName: e.target.value })}
          />
        ) : (
          item.teacherName
        ),

        StartTime: isEditing ? (
          <TextField
            type="datetime-local"
            sx={{ m: 1, width: 200 }}
            value={editedData.startTime || ''}
            // onChange={(e) => setEditedData({ ...editedData, startTime: e.target.value })}
            onChange={(e) => {
              const date = new Date(e.target.value);
              const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:00`;
              setEditedData({ ...editedData, startTime: formattedDate });
            }}
          />
        ) : (
          item.startTime
        ),
        Duration: isEditing ? (
          <TextField
            type="number"
            sx={{ m: 1, width: 200 }}
            value={editedData.duration || 0}
            onChange={(e) => setEditedData({ ...editedData, duration: e.target.value })}
          />
        ) : (
          item.duration
        ),
        Link: isEditing ? (
          <TextField
            type="text"
            sx={{ m: 1, width: 200 }}
            value={editedData.url || ''}
            onChange={(e) => setEditedData({ ...editedData, url: e.target.value })}
          />
        ) : (
          item.url
        ),
        Status: item.isActive ? (
          <Button variant="contained" color="primary">
            Active
          </Button>
        ) : (
          'Inactive'
        ),
        Action: isEditing ? (
          <div>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
              Save
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', gap: '2rem' }}>
            <input
              type="file"
              accept=".pdf,.doc,.docx,.xls,.xlsx"
              onChange={(event) => handleFileUploadAs(event, item.id)}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            />
            {/* <IconButton color="secondary" onClick={() => uploadedFileAs(null)}>
                <FaTrashAlt />
              </IconButton> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAssignmentUpload}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              Upload
            </Button>
            <Button variant="contained" color="primary" onClick={() => handleReportClick(item.id)}>
              Report
            </Button>
            <FaEdit style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => handleEditClick(item)} />
            <FaTrash style={{ cursor: 'pointer' }} onClick={() => handleDeleteSchedule(item.id)} />
          </div>
        ),
      };
    })
    : [];


  const handleSearch = (event) => {
    event.preventDefault();

    const searchData = showTable.filter((item) => {
      return item.scheduleName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.chapterName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.topicsToBePrepared?.toLowerCase().includes(search.toLowerCase()) ||
        item?.teacherName?.toLowerCase().includes(search.toLowerCase()) ||
        item?.courseDisplayName?.toLowerCase().includes(search.toLowerCase())
    });

    setFilteredData(searchData);
  };


  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Helmet>
        <title> Dashboard: Scheduler </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Scheduler
        </Typography>

        <Card style={{ width: '100%', maxWidth: '100%' }}>
          <CardContent
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <label htmlFor="containedfile">
                <Button variant="contained" color="primary" component="span" id="contained-button-file">
                  Import
                </Button>

                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.xls,.xlsx"
                  style={{ display: 'none' }}
                  id="containedfile"
                  onChange={handleFileUpload}
                />
              </label>
              {uploadedFile && (
                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                  {uploadedFile.name}
                </Typography>
              )}
            </div>
            {uploadedFile && (
              <Button variant="contained" color="primary" onClick={handleFormSubmit}>
                Upload
              </Button>
            )}
          </CardContent>

          <br />
          <Container>
            <form onSubmit={handleSearch} style={{ marginBottom: '20px' }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <TextField name="search" label="Search"
                  variant="outlined" style={{ width: 320 }}
                  onChange={(e) => { setSearch(e.target.value) }}
                />
                <Button type="submit" variant="contained" style={{ height: '40px' }}> Search </Button>
              </Stack>
            </form>
          </Container>
          <NewTableData data={specificColumns} headers={headers} currentPage={pageNo}  totalPages={totalRecord} rowsPerPageOptions={[20]} />
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Button variant="contained" color="primary" onClick={handlePrevPage} disabled={pageNo === 1}>
              Previous
            </Button>
            <Button variant="contained" color="primary" onClick={handleNextPage}>
              Next
            </Button>
          </div>
        </Card>
      </Container>
    </>
  );
}
