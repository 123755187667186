// ALL API END POINT

export const GET_REPORT = 'report/getReport';
export const GET_EXCEL = 'report/getExcelReport';
export const LOGIN_ENDPOINT = 'admin/login';
export const USERLOGIN = 'user/login';
export const GET_ALL_COURSE = 'api/master/getAllCourseDetails';
export const GET_ALL_SUBJECT = '/api/master/getAllSubjectByCourseId';
export const GET_ALL_CHAPTERS = '/api/master/getAllChapters';
export const GET_ALL_UAC = '/api/master/getAllTeachersUacRequest';
export const CREATE_UAC = '/api/master/createTeacherUac';
export const SEARCH_USER = '/user/search-users';
export const GET_USER = '/user/get-details';
export const ADD_USER = '/user/register-user';
export const GET_ASSIGN_COURSES = '/user/get-assign-course';
export const GET_COURSES = '/course/get-all-course';
export const GET_ALL_BOARDS = '/api/master/getAllBoardDetails';
export const EDIT_COURSE_DETAIL = '/course/edit-course';
export const ASSIGN_BOOK = '/user/admin-assign-book-multiple';
export const UAC_DOWNLOAD = '/api/master/getAllTeachersUacCodes';
export const NOTIFICATION_SEND = '/notification/send';
export const NOTIFICATION_GET = '/notification/get';
export const GET_PULISHER = '/api/master/getAllPublisherDetails';
export const ADD_COURSE = '/course/add-course';
export const UPDATE_COURSE = '/course/update-course';
export const ADD_SHEDULAR = '/schedule/add';
export const GET_ALL_SHEDULAR = '/schedule/get';
export const EDIT_SHEDULAR = '/schedule/edit';
export const UPDATE_SHEDULE = '/schedule/update';
export const EDIT_NOTIFICATION = '/notification/edit';
export const DELETE_ASSIGNED_COURSE = '/user/course-delete';
export const DELETE_USER = '/user/delete';
export const GET_ALLCLIENTS = '/teachersEdge/getAllClients';
export const CREATE_CLIENT = '/teachersEdge/createClient';
export const GET_TEACHER_EDGE_UAC = '/teachersEdge/getAllClientUacs';
export const CREATE_TEACHERS_EDGE = 'teachersEdge/createUAC';
export const ASSIGNE_ROLE = 'assignee/user-role';
export const DELETE_SCHEDULE = '/schedule/delete';
export const GET_QUIZ_LIST = '/quiz/scheduleDetails';
export const GET_QUIZ_TAG = '/assessment/getTag';
export const CREATE_QUIZ = '/quiz/create';
export const PUBLISH_QUIZ = '/quiz/schedule';
export const UPDATE_JOIN_QUIZ_STATUS = '/quiz/updateStatus';
export const INITIATE_QUIZ = '/quiz/updateStatus';
export const START_QUIZ = '/quiz/updateStatus';
export const GET_QUIZ_TO_PLAY = '/quiz/admin';
export const QUIZ_COMPLETE = '/quiz/updateStatus';
export const CHANGE_NEXT_QUESTION = '/quiz/updateStatus';
export const IMPORT_QUIZ = '/quiz/import';
export const GET_LEADER_BOARD = '/quiz/global-leader-board';
export const GET_USER_REPORT = '/quiz/admin-quiz-report';
export const GET_QUIZ_SUMMARY_REPORT = '/quiz/get-prize-report';
export const GET_QUIZ_HISTORY = '/quiz/scheduleQuiz';
export const GET_JOINED_USERS = '/quiz/participant';
export const GET_ATTEMPTED_ANSWER_DATA = '/quiz/question_attempt_data';
export const IMPORT_ASSESSMENT = '/assessment/import';
export const GET_STUDENT_REPORT_CARD = '/report/getReportCardUsers';
export const GET_ASSESMENT_BY_ID = '/tag/getAllQuestions';
export const GET_FINAL_REPORT_CARD = '/report/getReportCard';
export const IMPORT_ASSIGNMENT = '/assignment/import';
export const GET_ASSIGNMENT_REPORT = '/assignment/report';
export const LIVE_LECTURE_REPORT = '/content/user-content-access-count';
export const GET_INVOICE_LIST = '/master/getAllEkartInvoiceDetails';
export const SHIPROCKET_EXCEL = '/master/getShiprocketData';
export const GENERATE_CONTENT_QR = '/qrcode/generate';
export const IMPORT_AWB_SHIPROCKET = '/master/updateShiprocketAwb';
export const ALL_TE_CLIENT = '/teachersEdge/getAllTEClient';
export const GET_UAC_CODE_CLIENT = '/teachersEdge/getUacCodeForClient/';
export const CREATE_UAC_FOR_CLIENT = '/teachersEdge/createUacForClient';
export const SCREEN_CAST ='/setting/casting-all';
export const UPDATE_USER_PROFILE_NAME='/user/update-user-profile-name'
export const GET_INVOICE_DETAILS ='/master/getInvoiceDetails/';
export const GET_ALL_PICKUP_LOCATIONS ='/master/getPickupLocationList';
export const SHIP_SERVICE_AVAILABILITY ='/master/checkServicableAreaByPincodeAndPartner';
export const SHIP_TRACK_BY_AWB ='/master/getOrderTrackingByAwb?awbNumber=';
export const GET_SUB_STATUS_LIST ='/master/getSubStatusList/'
export const ADD_SUB_STATUS ='/master/addSubStatus'
export const CREATE_ORDER_BY_PARTNER ='/master/createOrderByPartner';
export const GENERATE_AWB_SHIPMENT ='/master/generateAwbShipment';
export const GENERATE_PICKUP ='/master/generatePickup';
export const GENERATE_LABEL ='/master/generateLabel';