import React, { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';

import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Shedular from './pages/Shedular';
import Notifications from './pages/Notifications';
import ManageTest from './pages/ManageTest';
import ManageUAC from './pages/ManageUAC';
import ManageNotification from './pages/ManageNotification';
import CreateUAC from './pages/CreateUAC';
import ManageUser from './pages/ManageUser'; // Import ManageUser
import UserPage from './pages/UserPage';
import Courses from './pages/Courses';
import CreateCourse from './pages/CreateCourse';
import CourseStructure from './pages/CourseStructure';
import TeachersEdgeUAC from './pages/TeachersEdgeUAC';
import StudentUAC from './pages/StudentUAC';
import CreateStudentUAC from './pages/CreateStudentUAC';
import CreateTeacharsUAC from './pages/CreateTeacharsUAC';
import AssignRole from './pages/AssignRole';
import ClientRegister from './pages/ClientRegister';
import CreateClient from './pages/CreateClient';
import QuizDashboard from './pages/QuizDashboard';
import Activity from './pages/Activity';
import GetQuizByTags from './pages/GetQuizByTags';
import PlayQuizPage from './pages/PlayQuizPage';
import ImportQuiz from './pages/ImportQuiz';
import QuizReports from './pages/QuizReports';
import ExpiredQuiz from './pages/ExpiredQuiz';
import ManageDemoUAC from './pages/ManageDemoUAC';
import CreateDemoUAC from './pages/CreateDemoUAC';
import StudentList from './pages/StudentList';
import StudentReport from './pages/StudentReport';
import AssignmentResult from './pages/AssignmentResult';
import StudentLectureReport from './pages/StudentLectureReport';
import InvoiceList from './pages/InvoiceList';
import GenerateQRContent from './pages/GenerateQRContent';
import CreateUacForClient from './pages/CreateUacForClient';
import CreateShipmentPage from './pages/CreateShipment';
import GenerateAwbShipment from './pages/GenerateAwbShipment';

export default function Router() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    if (role) {
      setAuthenticated(true);
    }
  }, []);

  // Define the routes based on user role
  const getDashboardRoute = () => {
    const role = localStorage.getItem('userRole');
    if (role === 'QUIZ_ADMIN') {
      return '/dashboard/QuizDashboard';
    }
    return '/dashboard/app';
  };

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/login" />, // Redirect to the login page by default
    },
    {
      path: '/login',
      element: authenticated ? <Navigate to={getDashboardRoute()} /> : <LoginPage />,
      index: true, // Set this as the default page
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={getDashboardRoute()} />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'manageuser', element: <ManageUser /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'Shedular', element: <Shedular /> },
        { path: 'Notification', element: <Notifications /> },
        { path: 'ManageTest', element: <ManageTest /> },
        { path: 'ManageUAC', element: <ManageUAC /> },
        { path: 'ManageNotification', element: <ManageNotification /> },
        { path: 'CreateUAC', element: <CreateUAC /> },
        { path: 'Courses', element: <Courses /> },
        { path: 'CreateCourse', element: <CreateCourse /> },
        // { path: 'CourseStructure', element: <CourseStructure /> },
        { path: 'StudentUAC', element: <StudentUAC /> },
        { path: 'TeachersEdgeUAC', element: <TeachersEdgeUAC /> },
        { path: 'CreateStudentUAC', element: <CreateStudentUAC /> },
        { path: 'CreateUacForClient', element: <CreateUacForClient /> },
        { path: 'CreateTeacharsUAC', element: <CreateTeacharsUAC /> },
        { path: 'AssignRole', element: <AssignRole /> },
        { path: 'ClientRegister', element: <ClientRegister /> },
        { path: 'CreateClient', element: <CreateClient /> },
        { path: 'QuizDashboard', element: <QuizDashboard /> },
        { path: 'Activity', element: <Activity /> },
        { path: 'GetQuizByTags', element: <GetQuizByTags /> },
        { path: 'PlayQuizPage', element: <PlayQuizPage /> },
        { path: 'ImportQuiz', element: <ImportQuiz /> },
        { path: 'QuizReports', element: <QuizReports /> },
        { path: 'ExpiredQuiz', element: <ExpiredQuiz /> },
        { path: 'ManageDemoUAC', element: <ManageDemoUAC /> },
        { path: 'CreateDemoUAC', element: <CreateDemoUAC /> },
        { path: 'StudentList', element: <StudentList /> },
        { path: 'StudentReport', element: <StudentReport /> },
        { path: 'AssignmentResult', element: <AssignmentResult /> },
        { path: 'StudentLectureReport', element: <StudentLectureReport /> },
        { path: 'InvoiceList', element: <InvoiceList /> },
        { path: 'CreateShipment', element: <CreateShipmentPage /> },
        { path: 'GenerateAWB', element: <GenerateAwbShipment /> },
        { path: 'GenerateQRContent', element: <GenerateQRContent /> },
      ],
    },
    {
      path: '/404',
      element: <SimpleLayout />,
      children: [{ element: <Page404 /> }],
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ]);

  return routes;
}
