import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stepper, Step, StepLabel, Typography, Container, Card, Avatar, Box, Grid, Dialog, DialogTitle, Divider, DialogContent, DialogActions, TextField } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { FaPlane, FaRoad } from 'react-icons/fa';
import { Item } from 'semantic-ui-react';
import { createOrderByPartner, generateAwbShipment, generatePickup, getAllPickupLocations, getShipSeverviceAvailability } from '../Servies/AdmiServices';
import Loader from '../components/common/Loader';


export default function CreateShipmentPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { order } = location.state;
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [pickupLocations, setPickupLocation] = useState(null)
    const [selectedService, setSelectedService] = useState(null);
    const steps = ['Get All Pickup Location', 'SHIP - Service Availability', 'Final Dispatch Order'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <PickupLocationStep setLoading={setLoading} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} />;
            case 1:
                return <ShipServiceAvailabilityStep setLoading={setLoading} selectedLocation={selectedLocation} selectedService={selectedService} setSelectedService={setSelectedService} />;
            case 2:
                return <FinalDispatchOrderStep setLoading={setLoading} selectedLocation={selectedLocation} selectedService={selectedService} order={order} />;
            default:
                return <div>Unknown step</div>;
        }
    };

    const isNextDisabled = () => {
        switch (activeStep) {
            case 0:
                return !selectedLocation;
            case 1:
                return !selectedService;
            default:
                return false;
        }
    };

    const loadPickupLocations = async () => {
        setLoading(true)
        try {
            getAllPickupLocations().then((res) => {
                if (res.data.status === "SUCCESS") {
                    setPickupLocation(res.data.response);
                    setLoading(false)
                } else {
                    toast.error("Error while getting pickup locations")
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log("Error occured::", error)
            setLoading(false)
        }
    }

    useEffect(() => {
        console.log(order)
        loadPickupLocations()
    }, [])

    const dispatchOrder = async () => {
        setLoading(true)
        try {
            const data = {
                "order_id": `${order.orderId.replace('PG0', '')}`,
                "channel_id": "",
                "pg_order_id": `${order.orderId}`,
                "invoice_number": `${order.invoiceNumber}`,
                "partner_id": "3",
                "pickup_id": `${selectedLocation.id}`
            }
            console.log("createOrderByPartner", data)
            createOrderByPartner({ "abc": "abc" }).then((res) => {
                if (res.status === 200) {
                    console.log(res.data)
                }
            })
            setLoading(false)
        } catch (error) {
            console.log("Error occured ", error)
        }
    }

    return (
        <>
            {loading && <Loader />}
            <Helmet>
                <title> Create Order By Partner </title>
            </Helmet>
            <Container>
                <Typography variant="h4" sx={{ mb: 3 }}>Dispatch Order</Typography>
                <Card sx={{ mb: 3 }} style={{ padding: '15px 15px' }}>

                    <h4 style={{ margin: '0px', padding: '0px' }}>Order Details</h4>
                    <Typography>
                        <strong>Order Id:</strong> {order.orderId} | <strong>Product:</strong>{order.productTitle} | <strong>Order Value:</strong> {order.totalsalevalue}<br />
                        <strong>Customer Name:</strong> {order.customerName} | <strong>Email:</strong> {order.email} | <strong>Contact No:</strong> {order.contactNo}
                    </Typography>
                    {selectedLocation && <p style={{ marginBottom: '0px' }}><strong>Pickup Location :</strong> {selectedLocation.firstName}, {selectedLocation?.city}/{selectedLocation?.state} - {selectedLocation.pincode} <br /><strong>Deliver To :</strong> {order.address1} {order.address2} {order.address3} - {order.pinCode}</p>}
                    {selectedService && <p style={{ marginTop: '0px' }}><strong>Courier Partner :</strong> {selectedService.courier_name}</p>}
                </Card>
                <Container>
                    <h3>Select Pickup Locations</h3>
                    <Card style={{ padding: '10px 5px', borderRadius: '5px', border: '1px solid grey', backgroundColor: '#80808026' }}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Item><h4>Name</h4></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><h4>Address</h4></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><h4>City/State</h4></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><h4>Pin Code</h4></Item>
                            </Grid>
                        </Grid>
                    </Card>
                    {pickupLocations && pickupLocations.map((location) => {
                        return <Card key={location?.id} style={{
                            cursor: 'pointer', marginTop: '5px', borderRadius: '5px', padding: '20px 15px',
                            border: selectedLocation?.id === location?.id ? '2px solid blue' : '1px solid gray',
                            backgroundColor: selectedLocation?.id === location?.id ? '#f0f8ff' : '#fff',
                        }}
                            onClick={() => setSelectedLocation(location)} >
                            <Grid container>
                                <Grid item xs={3} al>
                                    <Item><Typography variant="body1">{location?.firstName}</Typography></Item>
                                </Grid>
                                <Grid item xs={3} textAlign={'center'}>
                                    <Item><Typography variant="body1">{location?.addressLine1}</Typography></Item>
                                </Grid>
                                <Grid item xs={3} textAlign={'center'}>
                                    <Item><Typography variant="body1">{location?.city}/{location?.state}</Typography></Item>
                                </Grid>
                                <Grid item xs={3} textAlign={'center'}>
                                    <Item><Typography variant="body1">{location?.pincode}</Typography></Item>
                                </Grid>
                            </Grid>
                        </Card>
                    })}
                    <Button style={{ boxShadow: 'none ',marginTop:'5px' }} variant="contained" color="primary" onClick={dispatchOrder}>Dispatch Order</Button>
                </Container>
                
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography variant="h6" style={{ marginTop: '16px' }}>
                                Order Dispatched!
                            </Typography>
                            <Button onClick={() => { navigate('/dashboard/InvoiceList') }} style={{ marginTop: '16px' }}>
                                Go Back
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {renderStepContent(activeStep)}
                            <div style={{ marginTop: '16px' }}>
                                <Button variant="contained" sx={{ textAlign: 'center', marginLeft: '1rem', backgroundColor: 'white', color: 'black', }} onClick={() => { navigate('/dashboard/InvoiceList') }}>
                                    Cancel
                                </Button>
                                <Button disabled={activeStep === 0} onClick={handleBack} style={{ marginRight: '8px' }}>
                                    Back
                                </Button>
                                {activeStep !== steps.length - 1 && <Button variant="contained" color="primary" onClick={handleNext} disabled={isNextDisabled()}>
                                    Next
                                </Button>}
                            </div>
                        </div>
                    )}
                </div>
            </Container>
        </>
    );
};

const PickupLocationStep = ({ selectedLocation, setSelectedLocation, setLoading }) => {
    const [pickupLocations, setPickupLocation] = useState(null)

    useEffect(() => {
        loadPickupLocations()
    }, []);

    const loadPickupLocations = async () => {
        setLoading(true)
        try {
            getAllPickupLocations().then((res) => {
                if (res.data.status === "SUCCESS") {
                    setPickupLocation(res.data.response);
                    setLoading(false)
                } else {
                    toast.error("Error while getting pickup locations")
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log("Error occured::", error)
            setLoading(false)
        }
    }

    return (
        <div>
            <ToastContainer />
            <Container>
                <h3>Step 1: Select Pickup Locations</h3>
                <Card style={{ padding: '10px 5px', borderRadius: '5px', border: '1px solid grey', backgroundColor: '#80808026' }}>
                    <Grid container>
                        <Grid item xs={3}>
                            <Item><h4>Name</h4></Item>
                        </Grid>
                        <Grid item xs={3} textAlign={'center'}>
                            <Item><h4>Address</h4></Item>
                        </Grid>
                        <Grid item xs={3} textAlign={'center'}>
                            <Item><h4>City/State</h4></Item>
                        </Grid>
                        <Grid item xs={3} textAlign={'center'}>
                            <Item><h4>Pin Code</h4></Item>
                        </Grid>
                    </Grid>
                </Card>
                {pickupLocations && pickupLocations.map((location) => {
                    return <Card key={location?.id} style={{
                        cursor: 'pointer', marginTop: '5px', borderRadius: '5px', padding: '20px 15px',
                        border: selectedLocation?.id === location?.id ? '2px solid blue' : '1px solid gray',
                        backgroundColor: selectedLocation?.id === location?.id ? '#f0f8ff' : '#fff',
                    }}
                        onClick={() => setSelectedLocation(location)} >
                        <Grid container>
                            <Grid item xs={3} al>
                                <Item><Typography variant="body1">{location?.firstName}</Typography></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><Typography variant="body1">{location?.addressLine1}</Typography></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><Typography variant="body1">{location?.city}/{location?.state}</Typography></Item>
                            </Grid>
                            <Grid item xs={3} textAlign={'center'}>
                                <Item><Typography variant="body1">{location?.pincode}</Typography></Item>
                            </Grid>
                        </Grid>
                    </Card>
                })}
            </Container>
        </div>
    );
};

const ShipServiceAvailabilityStep = ({ setLoading, selectedLocation, selectedService, setSelectedService }) => {
    const allCourierType = [{ name: 'All', icon: '' }, { name: 'Surface', icon: <FaRoad /> }, { name: 'Air', icon: <FaPlane /> }]
    const [shipServices, setShipServices] = useState(null)
    const [filterData, setFilteredData] = useState(null)
    const [courierType, setCourierType] = useState('All')
    const location = useLocation();
    const { order } = location.state;
    useEffect(() => {
        console.log(order)
        console.log(selectedLocation)
        checkServiceAvailability();
    }, []);

    const checkServiceAvailability = async () => {
        setLoading(true);
        try {
            const data = {
                "sourcePincode": selectedLocation.pincode,
                "destinationPincode": order.pinCode,
                "weight": "3.5",
                "cod": "1",
                "partnerId": 3
            }
            getShipSeverviceAvailability(data).then((res) => {
                if (res.data.status === "SUCCESS") {
                    setShipServices(res.data.response);
                    setFilteredData(res.data.response);
                    setLoading(false)
                } else {
                    toast.error("Error while getting Shipping services")
                    setLoading(false)
                }
            })
        } catch (error) {
            console.log("Error occured::", error)
            setLoading(false)
        }
    }

    const setCourierPartnerType = (partner) => {
        setCourierType(partner)
        switch (partner) {
            case 'All':
                setFilteredData(shipServices)
                break;
            case 'Surface':
                setFilteredData(shipServices.filter((service) => {
                    return service.is_surface === true;
                }));
                break;
            case 'Air':
                setFilteredData(shipServices.filter((service) => {
                    return service.is_surface === false;
                }));
                break;
            default:
                setFilteredData(shipServices)
        }
    }

    return (
        <div>
            <ToastContainer />
            <Container>
                <h3>Step 2: Select Courier Partner</h3>
                <Card style={{ padding: '10px 15px', borderRadius: '5px', marginBottom: '5px' }}>
                    <Grid style={{ marginBottom: '5px' }}>
                        {allCourierType.map((type) => {
                            return <Button variant={courierType === type.name ? "contained" : "outlined"} onClick={() => { setCourierPartnerType(type.name) }} style={{ margin: '2px' }}>{type.name} &nbsp; {type.icon} </Button>
                        })}
                    </Grid>
                    {filterData && `${filterData.length} Couriers available`}
                </Card>
                <Card style={{ padding: '10px 5px', borderRadius: '5px', border: '1px solid black', boxShadow: 'none', backgroundColor: 'rgb(193 188 188 / 15%)' }}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Item><h4>Courier Partner</h4></Item>
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <Item><h4>Delivery Date</h4></Item>
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <Item><h4>Delivery Days</h4></Item>
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <Item><h4>Service Rate</h4></Item>
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <Item><h4>RTO Charges</h4></Item>
                        </Grid>
                        <Grid item xs={2} textAlign={'center'}>
                            <Item><h4>Ratings</h4></Item>
                        </Grid>
                    </Grid>
                </Card>
                {filterData && filterData.map((service) => {
                    return <Card key={service.id} sx={{ boxShadow: selectedService?.id === service?.id ? 0 : 2 }} style={{
                        cursor: 'pointer', marginTop: '10px', borderRadius: '5px', padding: '20px 15px',
                        border: selectedService?.id === service?.id ? '2px solid #3f50b5' : '',
                        backgroundColor: selectedService?.id === service?.id ? '#e3f2fd' : '#fff',
                    }}
                        onClick={() => setSelectedService(service)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Box display="flex" alignItems="center">
                                    <Avatar alt={service?.courier_name} src="..." style={{ marginRight: 8 }} />
                                    <Typography variant="body1">{service?.courier_name}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <Item><Typography variant="body1">{service?.etd}</Typography></Item>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <Item><Typography variant="body1">{service?.estimated_delivery_days}</Typography></Item>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <Item><Typography variant="body1">{service?.rate}</Typography></Item>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <Item><Typography variant="body1">{service?.rto_charges}</Typography></Item>
                            </Grid>
                            <Grid item xs={2} textAlign={'center'}>
                                <Item><Typography variant="body1">{service?.rating}</Typography></Item>
                            </Grid>
                        </Grid>
                    </Card>
                })}
            </Container>
        </div>
    );
};

const FinalDispatchOrderStep = ({ setLoading, selectedLocation, selectedService, order }) => {
    const [shipment, setShipment] = useState(null)
    const [popUpOpen, setPopUpOpen] = useState(false)
    const [pickupDate, setPickupDate] = useState(new Date().toISOString().split('T')[0])

    useEffect(() => {
        console.log(selectedLocation)
        console.log(selectedService)
        console.log('Finalizing dispatch order...');
    }, []);

    const dispatchOrder = async () => {
        setLoading(true)
        try {
            const data = {
                "order_id": `${order.orderId.replace('PG0', '')}`,
                "channel_id": "",
                "pg_order_id": `${order.orderId}`,
                "invoice_number": `${order.invoiceNumber}`,
                "partner_id": "3",
                "pickup_id": `${selectedLocation.id}`
            }
            console.log("createOrderByPartner", data)
            createOrderByPartner({ "abc": "abc" }).then((res) => {
                if (res.status === 200) {
                    setShipment(res.data)
                    generateAWB()
                }
            })
            setLoading(false)
        } catch (error) {
            console.log("Error occured ", error)
        }
    }

    const generateAWB = async () => {
        const data = {
            "shipment_id": shipment.shipment_id,
            "courier_id": selectedService.courier_company_id.toString(),
            "partner_id": 3
        }
        console.log("generateAwbShipment:", data)
        generateAwbShipment({}).then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                setPopUpOpen(true)
            }
        })
    }

    const schedulePickUp = async () => {
        const data = {
            "partner_id": 3,
            "shipment_id": [
                shipment.shipment_id
            ],
            "pickup_date": [
                pickupDate
            ]
        }
        console.log("generatePickup:", data)
        generatePickup({}).then((res) => {
            console.log(res.data)
        })
    }

    return (
        <div>
            <Container>
                <h3>Step 3: Dispatch Order</h3>
                <Card style={{ padding: 10 }}>
                    <strong>Customer Name:</strong> {order.customerName} | <strong>Email:</strong> {order.email} | <strong>Contact No:</strong> {order.contactNo}<br />
                    <strong>Order Id:</strong> {order.orderId.replace('PG0', '')} | <strong>PG Order Id:</strong> {order.orderId} | <strong>Invoice Number</strong>: {order.invoiceNumber} <br />
                    <strong>Product:</strong> {order.productTitle} | <strong>Order Value:</strong> {order.totalsalevalue}
                    {selectedLocation && <p style={{ marginBottom: '0px' }}><strong>Pickup Location :</strong> {selectedLocation.firstName}, {selectedLocation?.city}/{selectedLocation?.state} - {selectedLocation.pincode} <br /><strong>Deliver To :</strong> {order.address1} {order.address2} {order.address3} - {order.pinCode}</p>}
                    {selectedService && <p style={{ marginTop: '0px' }}><strong>Courier Partner :</strong> {selectedService.courier_name}</p>}
                    <Button style={{ boxShadow: 'none ' }} variant="contained" color="primary" onClick={dispatchOrder}>Dispatch Order</Button>
                </Card>
                <Dialog maxWidth={'md'} scroll={'body'} fullWidth open={popUpOpen} onClose={() => { setPopUpOpen(false) }}>
                    <DialogTitle>Schedule Your Pickup</DialogTitle>
                    <Divider />
                    <DialogContent style={{ justifyContent: 'center', alignContent: 'center' }}>
                        <p>Your package has been assigned to <strong>{selectedService.courier_name}</strong> successfully. The AWB number is of the same is <strong>{order.awbNo}</strong>.</p>
                        <p>
                            <strong>Pick Up Location</strong> <br />
                            {selectedLocation.firstName}, {selectedLocation?.city}/{selectedLocation?.state} - {selectedLocation.pincode}
                        </p>
                        <p>
                            <strong>Select Pickup Date</strong> <br />

                            <TextField id="pickup-date" style={{ marginTop: 15 }}
                                label="Pickup Date" type="date" value={pickupDate}
                                inputProps={{ min: new Date().toISOString().split('T')[0] }}
                                onChange={(e) => { console.log(e.target.value) }}
                                InputLabelProps={{ shrink: true, }}
                            />
                        </p>
                        <Button style={{ boxShadow: 'none ' }} variant="contained" color="primary" onClick={() => { schedulePickUp() }}>Schedule Pick Up</Button>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setPopUpOpen(false) }} color="primary">Close</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
};